* {
  margin: 0;
  padding: 0;
}
html {
  height: 100%;
}
body {
  font-family: 'Roboto', sans-serif;
  height: 100%;
}
#root {
  height: 100%;
}
.mr20 {
  margin-right: 20px;
}
.mt20 {
  margin-top: 20px;
}

.App {
  margin:0;
  padding:0;
  overflow:hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.row {
  margin: 0;
  padding: 10px;
}

.select {
  position: relative;
}
.select:after {
  content: '';
  position: absolute;
  right: 12px;
  top: 45%;
  transform: translateY(-50%) rotate(45deg);
  width: 5px;
  height: 5px;
  border-right: 2px solid #303030;
  border-bottom: 2px solid #303030;
}
select {
  position: relative;
  height: 37px;
  border: 0;
  background-color: #EEF1F3;
  padding: 4px 26px 4px 12px;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
select::-ms-expand {
  display: none;
}

.slider {
  position: fixed;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 0;
}
.slider .slider--content:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 40%);
  display: block;
  z-index: 2;
}
.slider .slider--content .slider {
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none;
  animation-delay: 40s;
  background-position: center;
  background-size: cover;
  z-index: 0;
}
.slider .slider--content .slider.active {
  animation: slideIn 40s infinite, zoom 40s infinite;
  z-index: 1;
}
@keyframes slideOut {
  12.5%{
    opacity: 0;
    pointer-events: auto;
  }
  25%{
    opacity: 0;
    pointer-events: auto;
  }
  37.5%{
    opacity: 1;
  }
}
@keyframes slideIn {
  12.5%{
    opacity: 1;
    pointer-events: auto;
  }
  25%{
    opacity: 1;
    pointer-events: auto;
  }
  37.5%{
    opacity: 0;
  }
}
@keyframes zoom {
  50%{
    transform: scale(1.25);
  }
}

.buttons {
  display: flex;
}
.buttons.align-right {
  justify-content: flex-end;
}
.btn {
  font-size: 15px;
  padding: 10px 22px;
  border: none;
  background-color: #f1f1f1;
  color: #333339;
  text-decoration: none;
  border-radius: 5px;
  text-transform: uppercase;
  display: inline-block;
  cursor: pointer;
}
.btn.wide {
  font-size: 16px;
  font-weight: 500;
  padding: 20px 22px;
}
.btn-green {
  color: #fff;
  background-color: #2ABB34;
}

.auth-block {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

}
.auth-block .container {
  max-width: 576px;
  width: 100%;
  padding: 0 14px;
}
.auth-block .title-block {
  font-size: 36px;
  font-weight: 400;
  text-align: center;
  color: #fff;
  margin-bottom: 30px;
  display: block;
}
.auth-block .main {
  background-color: #ffffff;
  border-radius: 15px;
  padding: 40px 10px;
  display: block;
}
.auth-block .main form {
  max-width: 408px;
  margin: 15px auto;
  display: flex;
  flex-direction: column;
}
.auth-block .main form.hidden {
  display: none;
}
.auth-block .main form .title {
  font-size: 15px;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.auth-block .main form input {
  font-size: 15px;
  border-radius: 5px;
  height: 58px;
  border: 1px solid #CACDCF;
  color: #333339;
  background-color: #fff;
  margin-bottom: 20px;
  padding: 0 14px;
}
.auth-block .main form button {
  margin-bottom: 16px;
}
.auth-block .main form .info-block {
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  color: rgba(51,51,57, .8);
  text-align: center;
}
.auth-block .main .form-auth-success {
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  color: #333;
  text-align: center;
  text-transform: uppercase;
}

header {
  background-color: #fff;
  padding: 10px 30px;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, .1);
  z-index: 1;
}
header .main {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
header .logo {
  display: flex;
  align-items: center;
}
header .logo .section {
  font-size: 16px;
  line-height: 16px;
  margin-left: 20px;
}
header .logo .section span {
  color: #fff;
  background-color: #EE1A1A;
  text-transform: uppercase;
  display: table;
  padding: 4px 10px 4px 7px;
}

header .timer {
  text-align: center;
}
header .timer .alert .text {
  font-size: 13px;
  color: #333339;
  text-transform: uppercase;
}
header .timer .alert .timer-counter {
  font-size: 20px;
  font-weight: bold;
  color: #232329;
  margin-top: 5px;
}
header .timer .alert .btn-pay {
  font-size: 14px;
  margin-top: 6px;
  padding: 8px 14px;
}

.iframe-tour {
  overflow: hidden;
  height: 100%;
  width: 100%;
  z-index: 999;
}

.container {
  margin: 0;
}
.container ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.container ul li {
  display: inline-block;
  margin-right: 9px;
}
.container ul li.active {
  text-decoration: underline;
}

.payments-block {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

}
.payments-block .container {
  max-width: 576px;
  width: 100%;
  padding: 0 14px;
}
.payments-block .title-block {
  font-size: 36px;
  font-weight: 400;
  text-align: center;
  color: #fff;
  margin-bottom: 30px;
  display: block;
}
.payments-block .main {
  background-color: #ffffff;
  border-radius: 15px;
  padding: 20px 10px;
  display: block;
}

.tableItems {
  position: relative;
}
.tableItems .titleBlock {
  margin-bottom: 20px;
}
.tableItems .cell {
  text-align: left;
}
.tableItems .cell .fs-12 {
  font-size: 12px !important;
}
.tableItems .cell .fs-13 {
  font-size: 13px !important;
}
.tableItems .cell.cell-5 {
  width: 5%;
}
.tableItems .cell.cell-10 {
  width: 10%;
}
.tableItems .cell.cell-13 {
  width: 13%;
}
.tableItems .cell.cell-15 {
  width: 15%;
}
.tableItems .cell.cell-16 {
  width: 16.6666666667%;
}
.tableItems .cell.cell-20 {
  width: 20%;
}
.tableItems .cell.cell-25 {
  width: 25%;
}
.tableItems .cell.cell-30 {
  width: 30%;
}
.tableItems .cell.cell-35 {
  width: 35%;
}
.tableItems .cell.cell-40 {
  width: 40%;
}
.tableItems .cell.cell-50 {
  width: 50%;
}
.tableItems .cell.cell-55 {
  width: 55%;
}
.tableItems .cell.cell-60 {
  width: 60%;
}
.tableItems .cell.cell-70 {
  width: 70%;
}
.tableItems .cell.cell-80 {
  width: 80%;
}
.tableItems .cell.cell-90 {
  width: 90%;
}
.tableItems .cell.cell-100 {
  width: 100%;
}
.tableItems .cell.align-left {
  text-align: left;
}
.tableItems .cell.align-center {
  text-align: center;
}
.tableItems .cell.align-right {
  text-align: right;
}
.tableItems .tableHeader {
  position: relative;
  width: 100%;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  display: table;
}
.tableItems .tableHeader .cell {
  font-size: 10px;
  line-height: 12px;
  color: #a1a1a1;
  text-transform: uppercase;
  padding: 12px 10px;
  display: table-cell;
  vertical-align: middle;
}
.tableItems .tableHeader .cell.pd20 {
  padding: 12px 20px;
}
.tableItems .tableContent {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 270px;
  overflow: hidden;
  overflow-y: scroll;
}
.tableItems .tableContent .item {
  position: relative;
  width: 100%;
  border-bottom: 1px solid #ebebeb;
  display: table;
  padding: 15px 0;
}
.tableItems .tableContent .item:last-child {
  border-bottom: none;
}
.tableItems .tableContent .item .cell {
  padding: 0 4px;
  display: table-cell;
  vertical-align: middle;
}
.tableItems .tableContent .item .cell:first-child {
  padding: 0 4px 0 7px;
}
.tableItems .tableContent .item .cell:first-child .name {
  font-size: 13px;
  white-space: nowrap;
}
.tableItems .tableContent .item .cell.pd20 {
  padding: 0 20px;
}
.tableItems .tableContent .item .cell.cell-cat {
  position: relative;
  padding: 0 20px;
}
.tableItems .tableContent .item .cell.cell-cat:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 1px;
  height: calc(100% + 31px);
  background-color: #ebebeb;
  margin: auto;
  display: inline-block;
}
.tableItems .tableContent .item .cell.cell-cat .name {
  font-size: 10px;
  line-height: 12px;
  color: #a1a1a1;
  text-transform: uppercase;
}
.tableItems .tableContent .item .content {
  padding: 2px 0 0 14px;
  display: table-cell;
  vertical-align: top;
}
.tableItems .tableContent .item .content.no-padding {
  padding: 0;
}
.tableItems .tableContent .item .content .title {
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  color: #333;
  margin: 0 0 10px;
  display: block;
}
.tableItems .tableContent .item .content .email {
  font-size: 13px;
  font-weight: 400;
  color: #a9a9a9;
}
.tableItems .tableContent .item .content .message {
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
  color: #777777;
  word-break: break-word;
}
.tableItems .tableContent .item .name {
  font-size: 14px;
  line-height: 16px;
  color: #656565;
  word-break: normal;
  display: block;
}
.tableItems .tableContent .item .name.black {
  color: #555;
}
.tableItems .emptyContent {
  font-size: 14px;
  border-radius: 3px;
  background-color: #f7f7f7;
  color: #444;
  text-align: center;
  margin: 20px 0 0;
  padding: 20px;
}

@media (max-width: 450px) {
  header .logo .section {
    font-size: 12px;
    line-height: 12px;
    margin-left: 20px;
  }
  header .select select {
    font-size: 12px;
    margin-right: 0px !important;
  }
  header .select {
    margin-right: 0px !important;
  }
  .select:after {
    width: 3px;
    height: 3px;
  }
}